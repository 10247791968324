@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
@import url('https://jquense.github.io/react-big-calendar/examples/runtime~main.0c2c7d8a.iframe.bundle.js');
@import url('https://jquense.github.io/react-big-calendar/examples/88.7f6cf151.iframe.bundle.js');
@import url('https://jquense.github.io/react-big-calendar/examples/main.caa9adb3.iframe.bundle.js');


body {
  font-family: 'Poppins', sans-serif;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a:hover {
  text-decoration: none;
}

.login-layout {
  background: #f1f1f1;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  width: 500px;
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  max-width: 100%;
  box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.125);
}

.login-box .form-group label {
  color: #666;
  margin: 0 0 4px 0;
}

.login-box .form-group .form-control {
  border: 2px solid #b9b9b9;
  height: auto;
  padding: 8px 15px;
}

.login-box .form-group {
  position: relative;
}

.login-box .form-group span {
  position: absolute;
  right: 10px;
  top: 38px;
  font-size: 17px;
  color: #666;
}

.login-box .form-group span button {
  background: transparent;
  border: none;
  padding: 0;
  color: #666;
}

.theme-button {
  background: #4040f9;
  border: none;
  color: #fff;
  display: block;
  padding: 9px 44px;
  font-weight: 500;
}

ul.bottom-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Sidebar-left {
  position: fixed;
  left: 0;
  top: 70px;
  background: #525A65;
  bottom: 0;
  width: 18%;
  height: 90%;
  overflow-y: scroll;
  transition: .4s ease-in-out;
}

.Sidebar-left:not(.closes) {
  left: 0;
  margin-left: -90%;
  transition: .4s ease-in-out;
}

.App.flex-layout:not(.closes) .common-layout {
  padding-left: 0;
  transition: .4s ease-in-out;
}

.topbar-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #525A65;
  padding: 10px 15px;
  background: #fff;
  z-index: 99;
}

.common-layout {
  padding-left: 18%;
  padding-top: 70px;
  min-height: 100vh;
  transition: .4s ease-in-out;
}

.topbar-header ul.header-right-m {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.topbar-header ul li span {
  color: cadetblue;
}

.topbar-header ul li {
  color: #3F4756;
  font-size: 17px;
  font-weight: 500;
}

.topbar-header ul li button {
  border: none;
  background: transparent;
  color: #3F4756;
  font-size: 17px;
  font-weight: 500;
  padding: 0;
}

.Sidebar-left li a {
  color: #fff;
  display: block;
  padding: 5px 10px 5px 22px;
  font-weight: 500;
  font-size: 18px;
}

.Sidebar-left li a.active {
  background: #61738d;
}

.topbar-header button {
  background: transparent;
  border: none;
  padding: 0;
}

.innerlayout h3 {
  margin: auto;
  font-size: 16px;
  font-weight: 700;
}

.form-style .form-group .form-control {
  border: 2px solid #b9b9b9;
  height: auto;
  padding: 11px 15px;
  font-size: 13px;
}

.form-style .form-group label {
  color: #666;
  margin: 0 0 4px 0;
}

.formBtn {
  background: #83BEDF;
  border: none;
  color: #fff;
  display: block;
  padding: 9px 44px;
  font-weight: 500;
  text-align: center;
}

.button-part ul {
  display: flex;
  align-items: center;
  gap: 20px;
}

.formBtn1 {
  background: #2497d7;
  border: none;
  color: #fff;
  display: block;
  padding: 9px 18px;
  font-weight: 500;
}

.custom-table {
  width: 100%;
}

.custom-table th {
  border: 1px solid #ccc;
  padding: 9px 7px;
  font-size: 13px;
  text-transform: capitalize;
}

.custom-table td {
  border: 1px solid #ccc;
  padding: 9px 7px;
  font-size: 13px;
  word-wrap: break-word;
}

.custom-table td button {
  color: red;
  border: none;
  padding: 0;
  background: transparent;
  margin: 0 8px;
}

.loaaderIcon {
  animation: rotation infinite .5s linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.CustomLoader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  align-items: center;
  z-index: 99999;
}

.CustomLoaderInner {
  background: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 19px 11px;
  border-radius: 2px;
  width: 88%;
}

.CustomLoaderInner h3 {
  font-size: 15px;
  margin: 0;
}

.react-datepicker-wrapper {
  display: block;
}

.card-box {
  width: 500px;
  margin: 0 auto;
  max-width: 100%;
  border: 1px solid #ccc;
  padding: 20px;
}

.card-box li {
  margin: 10px 0;
}

ul.intersetlist {
  /* column-count: 3; */
  /* column-gap: 10px; */
  border-left: 1px solid #000;
  /* padding: 0 0 0 22px; */
}

ul.intersetlist label {
  margin: 0 0 0 6px;
  font-size: 14px;
}

ul.intersetlist input[type="checkbox"] {
  position: relative;
  top: 2px;
}

ul.intersetlist li {
  position: relative;
  margin-bottom: 7px;
  padding: 0 0 0 15px;
}

ul.intersetlist li::before {
  content: "";
  position: absolute;
  top: 12px;
  width: 15px;
  left: 0;
  background: #000;
  height: 1px;
}

li.groupChildEle {
  padding-left: 20px !important;
}

.multiSelectContainer li {
  padding: 5px;
  font-size: 15px;
}

.view-page .form-group label {
  margin: 0;
  font-size: 14px;
}

.view-page .form-group b {
  font-size: 13px;
}

ul.pagination {
  gap: 5px;
  margin-top: 12px;
  justify-content: flex-end;
}

.pagination li button {
  border: 1px solid #000;
  background: transparent;
  padding: 2px 13px;
  height: auto;
  border-radius: 3px;
}

.pagination li button:hover {
  background: #000;
  color: #fff;
}

.pagination button.active {
  background: #000;
  color: #fff;
}

ul.columnlist {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

ul.columnlist label {
  margin: 0 0 0 6px;
  position: relative;
  top: -1px;
}

.Sidebar-left li ul {
  padding-left: 24px;
}


.Sidebar-left li button.accordion-button {
  color: #fff;
  display: block;
  padding: 5px 10px 5px 22px;
  font-weight: 500;
  font-size: 18px;
  background: transparent;
  border: none;
  width: 100%;
  text-align: left;
  position: relative;
}

.Sidebar-left li h2.accordion-header {
  margin: 0;
}

.Sidebar-left li button.accordion-button.collapsed::before {
  content: "\f107";
  font-family: 'FontAwesome';
  position: absolute;
  right: 9px;
  top: 6px;
}

.Sidebar-left li button.accordion-button::before {
  content: "\f106";
  font-family: 'FontAwesome';
  position: absolute;
  right: 9px;
  top: 6px;
}

.module_list {
  border: 1px solid #ccc;
  padding: 15px;
}

.module_list-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.module_list-top h5 {
  margin: 0;
  font-weight: 500;
}

.iyZJxS {
  opacity: 1 !important;
}

.RFyfv {
  opacity: 1 !important;
}

.table-btn {
  color: red;
  border: none;
  padding: 0;
  background: transparent;
  margin: 0 8px;
}

.testradio {
  display: flex;
  position: absolute;
  margin: 17px 10px;
}

.testadd {
  width: 150px;
  position: absolute;
  margin: -45px 850px;
}

.maintest {
  border: 1px solid;
  color: rgba(0, 0, 0, 0.5);
  width: 100%;
  padding: 5px 0px 5px 5px;
}

.testquestion {
  height: 50px;
  padding: 11px 30px;
}

.testanswer {
  height: 50px;
  padding: 11px 30px;
}

.selectlabel {
  font-size: 12px;
}

.asterisk {
  color: red;
}

.profileimg {
  border: 3px solid;
  position: relative;
  color: rgba(0, 0, 0, 0.50);
  margin-top: -80px;
  width: 145px;
  height: 150px;
}

.hover-text {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.profileimg:hover .hover-text {
  display: block;
}


.brand-logo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.brand-logo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.brand-logo h5 {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
}

.form-control.error {
  box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%)
}

.view-accordian button.accordion-button {
  border: none;
  background: transparent;
  padding: 0 0 7px 0;
  font-size: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  width: 100%;
  text-align: left;
  position: relative;
}

.view-accordian h2.accordion-header {
  margin: 0;
}

.view-accordian button.accordion-button.collapsed::before {
  content: "\f107";
  font-family: 'FontAwesome';
  position: absolute;
  right: 0;
}

.view-accordian button.accordion-button::before {
  content: "\f106";
  font-family: 'FontAwesome';
  position: absolute;
  right: 0;
}

.imgsize {
  width: 100%;
  height: 100%;
}

.question-accordian button.accordion-button {
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  padding: 10px 15px 10px 10px;
  font-size: 15px;
  background: #ececff;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  margin-top: 10px;
  position: relative;
  font-weight: 600;
}

.question-accordian button.accordion-button.collapsed::before {
  content: "\f107";
  font-family: 'FontAwesome';
  position: absolute;
  right: 5px;
}

.question-accordian button.accordion-button::before {
  content: "\f106";
  font-family: 'FontAwesome';
  position: absolute;
  right: 5px;
}

.q-table-body li {
  display: flex;
  text-align: left;
  box-shadow: 1px 6px 4px 0px rgba(0, 0, 0, 0.125);
}

.q-table-head li h2 {
  flex: 1;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #ccc;
  margin: 0;
  padding: 10px;
}

.q-table-head li {
  display: flex;
}

.innerlayout h3 {
  margin: 0;
  /* font-size: 14px;
  font-weight: 400; */
  font-size: 20px;
  font-weight: 500;
  flex: 1;
  padding: 10px 10px;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.innerlayout h3 p {
  margin: 0;
}

.question-details ul li {
  margin-bottom: 7px;
}

.custom-dropdown-checkbox button {
  border: 2px solid #b9b9b9 !important;
  height: auto;
  padding: 11px 15px;
  font-size: 13px;
  background: #fff !important;
  color: #222 !important;
  display: block;
  width: 100%;
  text-align: left;
}

.custom-dropdown-checkbox .dropdown-menu {
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fff;
}

.custom-dropdown-checkbox .dropdown-menu li {
  padding: 3px 25px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.custom-dropdown-checkbox .dropdown-menu li label {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
}

.custom-dropdown-checkbox .dropdown-menu li:first-child {
  padding: 3px 9px;
}

.test-modal .modal-dialog {
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  margin: 0;
}

.test-modal .modal-dialog .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
}

.test-modal .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.test-top {
  background: #202893;
  padding: 0px 15px;
}

.test-top ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.test-top ul li {
  color: #fff;
  font-size: 17px;
}

.test-top ul li span {
  background: orange;
  padding: 2px 10px;
}

.test-top button.closeBtn {
  border: none;
  background: transparent;
  color: #fff;
  font-size: 34px;
}

.question-set h5 {
  font-size: 17px;
  margin: 27px 0 22px 0;
}

.question-set li button {
  display: block;
  border: none;
  width: 95%;
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  padding: 12px 39px;
  margin-left: 42px;
  position: relative;
}

.question-set li {
  margin-bottom: 8px;
  position: relative;
}

.question-set li::before {
  position: absolute;
  top: 13px;
  left: 13px;
  content: "";
  font-weight: 700;
}

.question-set li:first-child:before {
  content: "a)";
}

.question-set li:nth-child(2):before {
  content: "b)";
}

.question-set li:nth-child(3):before {
  content: "c)";
}

.question-set li:nth-child(4):before {
  content: "d)";
}

.question-set li:nth-child(5):before {
  content: "e)";
}

.question-set li:nth-child(6):before {
  content: "f)";
}

ul.questionBtn {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  gap: 10px;
}

.question-set li button::before {
  content: "";
  position: absolute;
  top: 14px;
  left: 9px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #000;
}

.question-set li button.selected {
  background: #202893;
  color: #fff;
}

.question-set li button.selected::after {
  content: "";
  position: absolute;
  top: 19px;
  left: 14px;
  width: 10px;
  height: 10px;
  background: #202893;
  border-radius: 50%;
}

.assignbtn {
  font-size: 12px;
  background: #2497d7;
  border: none;
  color: #fff;
  display: block;
  padding: 5px 5px;
  font-weight: 500;
  width: 100px;

}

.q-table-body h3 {
  font-size: 14px;
  font-weight: 400;
}

ul.existingQuestion-list li p {
  margin: 0;
}

ul.existingQuestion-list li:first-child {
  padding: 3px 25px !important;
}

span.timeseparate {
  display: block;
  border-bottom: 1px solid #ccc;
  padding: 10px 15px;
}

.attendence-table td {
  border: 1px solid #ccc;
  vertical-align: middle;
}

span.timeseparate:last-child {
  border-bottom: none;
}

/* table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

td {
  border: 1px solid #ccc;
  padding: 0.5rem;
} */

ul.admin-ern-table {
  display: flex;
  gap: 10px;
}

ul.admin-ern-table li {
  flex: 1 1;
  font-size: 14px;
  padding: 7px 10px;
}

table.admin-ern-table-inner-table {
  border: 1px solid #ccc;
  width: 100%;
}

.custom-table th {
  border-bottom: 1px solid #dfe0eb !important;
  color: #000;
  font-weight: 700;
  text-transform: capitalize;
}

.accordian-table-container.custom-table {
  border: 1px solid #ccc;
}

table.admin-ern-table-inner-table {
  border: 1px solid #ccc;
  width: 95%;
  margin: 13px auto;
}

span.arrow-acc {
  /* position: absolute; */
  position: relative;
  left: 10px;
  top: 5px;
  cursor: pointer;
}

.noquestion {
  opacity: 0.5;
}

.test-overview {
  height: 100%;
  border-left: 1px solid #ccc;
  padding-left: 20px;
}

.test-overview .question_noList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.test-overview .question_noList li {
  width: 18%;
}

.test-overview .question_noList li span {
  width: 40px;
  height: 40px;
  background: #f1f1f1;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

ul.question_info {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.test-overview .question_noList li.touched span {
  background: #202893;
  color: #fff;
}

.test-overview .question_noList li.not-touched span {
  background: orange;
}

ul.question_info {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

ul.question_info li {
  flex: 1;
  font-size: 14px;
  display: flex;
  gap: 6px;
  align-items: center;
}

ul.question_info span {
  width: 16px;
  height: 16px;
  background: #f1f1f1;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

ul.question_info span.not-touched {
  background: orange;
}

ul.question_info span.touched {
  background: #202893;
  color: #fff;
}

.studentassignbtn {
  font-size: 12px;
  background: #2497d7;
  border: none;
  color: #fff;
  display: block;
  padding: 5px 5px;
  font-weight: 500;
  width: 70px;
}

.chart-responsive {
  display: block;
  width: 80%;
  margin-top: 100px;
  margin-left: 240px;
  overflow-x: auto;
}

.topic_list {
  display: flex;
  align-items: center;
  gap: 32px;
  margin: 5px 0;
  position: relative;
  padding: 0 0 0 0;
}

.topic_list label {
  margin: 0;
  font-size: 13px;
  position: absolute;
  left: -160px;
  top: 0;
  width: 126px;
  text-align: right;
  line-height: 1;
}

.chart_bg {
  display: flex;
}

.topic_list label::before {
  content: "";
  position: absolute;
  right: -25px;
  width: 20px;
  height: 1px;
  background: #ccc;
  top: 6px;
}

.chart_view_horizental {
  border: 1px solid #ccc;
  padding: 0;
  margin-left: 160px;
}

.chart_view_verticle {
  padding-left: 160px;
  padding-top: 24px;
}

.chart_view_verticle ul li {
  position: relative;
  font-size: 12px;
}

.chart_view_verticle ul li::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 3px;
  height: 16px;
  width: 1px;
  background: #ccc;
}

.chart_view_verticle ul {
  display: flex;
}

.hover-info {
  position: absolute;
  bottom: 27px;
  left: 0;
  width: 131px;
  background: #f2f2f2;
  padding: 5px;
  z-index: 999;
  border-radius: 4px;
  display: none;
}

.hover-info::before {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #f2f2f2;
}

.hover-info h5 {
  font-size: 12px;
  margin: 0 0 1px 0;
}

.hover-info h6 {
  font-size: 11px;
  margin: 0;
}

.chart_bg_correct {
  position: relative;
}

.chart_bg_incorrect {
  position: relative;
}

.chart_bg_notattempt {
  position: relative;
}

.chart_bg_correct:hover .hover-info {
  display: block;
}

.chart_bg_incorrect:hover .hover-info {
  display: block;
}

.chart_bg_notattempt:hover .hover-info {
  display: block;
}

.for-suggestion {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 5px;
  height: 40px;
}

.for-suggestion-b {
  display: flex;
  align-items: center;
  gap: 8px;
}

.for-suggestion-b span {
  width: 18px;
  height: 18px;
  background: #f2f2f2;
  border-radius: 5px;
}

.for-suggestion-b label {
  margin: 0;
  font-size: 14px;
}

ol.list_result {
  margin-top: 30px;
}

ol.list_result h4 {
  font-size: 16px;
}

ol.list_result ul li {
  margin-bottom: 10px;
}

ol.list_result b {
  font-size: 14px;
}

ol.list_result p {
  margin: 0;
  font-size: 14px;
}

table.chart-table-border {
  width: 100%;
}

table.chart-table-border th {
  font-size: 14px;
  border: 1px solid #ccc;
  padding: 10px;
}

table.chart-table-border td {
  font-size: 14px;
  border: 1px solid #ccc;
  padding: 10px;
}

.container {
  margin: 0 auto;
  width: 600px;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  padding: 20px
}

.lp-drop-table ul.q-table-body {
  min-width: 2500px;
  max-width: 3500px;
}

.lp-drop-table ul.q-table-head {
  min-width: 2500px;
  max-width: 3500px;
}

.service_accordian {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
  position: relative;
}

.service_accordian h2.accordion-header {
  position: absolute;
  right: 20px;
  top: 3px;
}

.resetBtn {
  margin-left: 15px;
  border-radius: 12px;
  height: 30px;
  align-items: center;
  display: flex;
  gap: 7px;
}

.guidelinesnotes {
  height: auto;
  padding: 11px 15px;
  font-size: 12px;
  background-color: unset;
  border: unset;

}

.recurringdetails {
  height: 20.3rem;
  position: absolute;
  width: 92%;
}

.digitaltime {
  margin: 0;
  display: grid;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
}

.workout-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #525A65;
  padding: 10px 15px;
  background: #fff;
  z-index: 99;
}

.taglevel {
  font-size: 13px !important;
  color: #424242;
  margin-bottom: 5px;
}

.taglistul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.custom1 {
  padding-bottom: 0;
  margin-bottom: 0 !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0px;
}

.taglistli {
  margin-right: 10px;
  cursor: pointer;
  background: #5577FF;
  margin-bottom: 5px;
  border-radius: 18px;
  color: white;
  padding: 3px 10px;
  font-size: 12px;
}

.sessiondetails {
  display: flex;
  justify-content: end;
  margin-bottom: 12px;
}

.billing {
  background-color: #f8f8f8;
  width: 60%;
  height: 80%;
  position: absolute;
  align-content: baseline;
  margin-left: 75px;
}

.erolldetails {
  border: 1px;
  border-radius: 6px;
  background: #eaeaea;
  padding: 5px 0px 0px 10px;
  margin-left: 15px;
}

.paybtn {
  background-color: rgb(213, 61, 0);
  width: 11rem;
  text-align: center;
}

.linkbtn {
  width: 11rem;
  text-align: center;
}


.schedule-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.employee-schedule {
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 4px;
}

.employee-schedule h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.employee-schedule ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.employee-schedule li {
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.reportbody {
  padding-left: 20%;
  padding-top: 10px;
  min-height: 64vh;
  transition: .4s ease-in-out;
  border: 1px solid;
  width: 49rem;
  margin-top: -359px;
  float: right;
}

.detailreport {
  float: right;
  font-size: 14px;
  margin-top: 2px;
  margin-right: -15px;
}

.infoBox {
  margin-left: -16px;
  background-color: rgb(204, 235, 235);
  border-radius: 6px;
  border-top-left-radius: 0px;
  min-height: unset;
}

.icon {
  font-size: 16px;
  width: 17px;
  height: 15px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.paginationNav {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.paginationNav:disabled {
  cursor: initial;
  opacity: 0.3;
}

.paginationNav:hover {
  background-color: #E5E9EC;
}

.paginationNav:disabled:hover {
  background-color: initial;
}

.paginationNav:focus {
  outline: none;
}

.paginationNumber {
  cursor: pointer;
  padding: 0 10px;
}

.paginationNumber--active {
  color: blue;
  font-weight: bolder;
  padding: 0 10px;
  line-height: 30px;
  border-radius: 20px;
  background-color: rgb(217, 214, 247);
}

.paginationNumber:hover {
  opacity: 0.5;
}

.pX-10 {
  padding: 0 10px;
}

.tooltip {
  position: absolute;
  bottom: 100%; /* Position above the table cell */
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  color: #333;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  z-index: 1000;
  white-space: nowrap;
}

.main-div {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 0px;
}

.ck-content {
    direction: ltr !important;
}


.homeworklink {
  border: 2px solid lightgray;
  padding: 10px 0px 0px 10px;
  margin: 10px 0px 0px 15px;
  max-width: 97.1%;
  text-align-last: start;
  font-weight: 600;
}

.module-name {
  border: 1px solid #ccc; /* Adjust the color and thickness as needed */
  padding: 8px; /* Add some padding for better spacing */
  margin: 5px 0; /* Add margin for spacing between modules */
  background-color: rgb(176, 226, 242); /* Optional: Add a background color */
  cursor: pointer; /* Change cursor to pointer for better UX */
}

.module-name:hover {
  background-color: #81d4fa; /* Optional: Change background on hover */
}

/* Style for syllabus row */
.admin-ern-table {
  background-color: rgb(255, 255, 255); /* Light green background for syllabus */
  cursor: pointer; /* Change cursor to pointer for better UX */
}

/* Change background color on hover for syllabus rows */
.admin-ern-table:hover {
  background-color: rgb(231, 240, 231); /* Change background on hover */
}

.arrow-acc {
  display: inline-block; /* Ensure the arrow is inline with the text */
  transition: transform 0.3s ease; /* Smooth transition for rotation */
}

/* Rotate the arrow when the syllabus or module is open */
.arrow-acc.open {
  transform: rotate(90deg); /* Rotate the arrow 90 degrees */
}

/* Style for the topic name */
.admin-ern-table-inner-table {
  background-color: #f2e0f6; /* Light violet background for topics */
}

/* Change background color on hover for topic names */
.admin-ern-table-inner-table tr:hover {
  background-color: #e9bbf1; /* Slightly darker violet on hover */
}